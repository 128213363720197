import React from 'react';
import Logo from '../images/logo.svg';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Layout from './ui/layout';
import screens from '../@style/screens';

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  @media ${screens.laptop} {
    margin-top: 30px;
    flex-direction: row;
    align-items: baseline;
  }
`;

const Label = styled.h4`
  font-size: 32px;
  font-weight: 700;
  @media ${screens.laptop} {
    margin-left: 20px;
  }
`;

const StyledHR = styled.hr`
  border-top: 1px solid #00a3e0;
  width: 100%;
  margin: 20px 0 30px 0;
  @media ${screens.laptop} {
    border-width: 2px;
    margin: 30px 0 40px 0;
  }
`;

const LogoSmaller = styled(Logo)`
  width: 77px;
  height: 35px;
`;

const Header: React.FC = () => {
  return (
    <>
      <Layout>
        <HeaderWrapper>
          <Link to="/">
            <LogoSmaller />
          </Link>

          <Link to="/">
            <Label>OPEN BUILDER</Label>
          </Link>
        </HeaderWrapper>
      </Layout>
      <StyledHR />
    </>
  );
};

export default Header;
