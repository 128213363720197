import React from 'react';
import Logo from '../images/logo.svg';
import styled from 'styled-components';
import Button from './ui/button';
import { Link } from 'gatsby';
import Layout from './ui/layout';
import screens from '../@style/screens';

const LogoSmaller = styled(Logo)`
  width: 51px;
  height: 23px;
  @media ${screens.laptop} {
    width: 77px;
    height: 35px;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  @media ${screens.laptop} {
    margin-bottom: 2rem;
  }
`;

const FooterLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  @media ${screens.laptop} {
    flex-direction: row;
  }
`;

const Label = styled.h4`
  font-size: 14px;
  font-weight: 700;
  padding-top: 10px;
  @media ${screens.laptop} {
    padding-top: 0;
    font-size: 24px;
    margin-left: 20px;
  }
`;

const FooterCopy = styled.h4`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  * {
    font-size: 10px;
    font-weight: 400;
  }
  @media ${screens.laptop} {
    * {
      font-size: 18px;
    }
  }
`;

const StyledHR = styled.hr`
  border-top: 1px solid #00a3e0;
  width: 100%;
  margin: 30px 0 20px 0;
  @media ${screens.laptop} {
    margin: 70px 0 2rem 0;
    border-width: 2px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${screens.laptop} {
    flex-direction: row;
  }
`;

const Row = styled.div`
  display: flex;
  margin-top: 4px;
  @media ${screens.laptop} {
    margin-top: 10px;
  }
`;

const Wrapper = styled.div`
  margin-top: auto;
`;

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <StyledHR />
      <Layout>
        <FooterWrapper>
          <Link to="/">
            <FooterLogoWrapper>
              <LogoSmaller />
              <Label>OPEN BUILDER</Label>
            </FooterLogoWrapper>
          </Link>
          <FooterCopy>
            <LinkWrapper>
              3D Content Powered by &nbsp;
              <Button
                link="https://www.lowesinnovationlabs.com/"
                buttonStyle="text"
                type="external"
                underline
              >
                Lowe’s Innovation Labs
              </Button>
            </LinkWrapper>
            <Row>
              <Button
                link="https://www.lowes.com/l/about/privacy-and-security-statement"
                buttonStyle="text"
                type="external"
                underline
              >
                Privacy
              </Button>
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              <Button link="/terms" buttonStyle="text" type="internal" underline>
                Terms of Use
              </Button>
            </Row>
          </FooterCopy>
        </FooterWrapper>
      </Layout>
    </Wrapper>
  );
};

export default Footer;
