import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import screens from '../../@style/screens';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  link?: string;
  type?: 'internal' | 'external';
  buttonStyle?: 'text' | 'fit' | 'standard';
  selected?: boolean;
  underline?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
}

const Wrapper = styled.div<Props>`
  ${({ selected }) => {
    if (selected) {
      return 'background-color: #00A3E0';
    }
    return 'background-color: #012169';
  }};
  width: fit-content;
  min-width: ${({ buttonStyle }) => (buttonStyle === 'fit' ? 'max-content' : '80%')};
  height: 40px;
  margin: 10px 0;
  display: flex;
  border: 2px solid #00a3e0;
  border-radius: 35px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 0 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  @media ${screens.laptop} {
    height: 60px;
    min-width: ${({ buttonStyle }) => (buttonStyle === 'fit' ? 'max-content' : '300px')};
  }
  @media ${screens.standard} {
    height: 65px;
    padding: 0 50px;
  }

  ${({ buttonStyle }) => {
    if (buttonStyle === 'text') {
      return `
        border: none;
        min-width: unset !important;
        padding: 0 !important;
        background-color: unset;
        height: unset !important;
        margin: 0;
      `;
    }
    return '';
  }}
  &:hover {
    ${({ buttonStyle, disabled }) => {
      if (buttonStyle === 'text') {
        return 'color: #00a3e0;';
      }

      return disabled ? 'background-color: #012169;' : 'background-color: #00a3e0;';
    }}
`;

const Label = styled.h4`
  display: flex;
  font-size: 17px;
  margin: auto;
  font-weight: bold;
  align-items: center;
  @media ${screens.laptop} {
    font-size: 20px;
  }
  @media ${screens.standard} {
    font-size: 26px;
  }
`;

type StyledAProps = Pick<Props, 'underline'>;

const StyledA = styled.a<StyledAProps>`
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
`;

const StyledLink = styled<any>(Link)`
  // bad styled-components types! should be typeof Link & StyledAProps
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
`;

const Button: React.FC<Props> = ({ link, type, underline, children, ...rest }) => {
  const LinkWrapper = ({ children: child }: { children: React.ReactNode }) => {
    if (link) {
      if (type === 'internal') {
        return (
          <StyledLink to={link} underline={underline?.toString()}>
            {child}
          </StyledLink>
        );
      }
      return (
        <StyledA href={link} underline={underline} target="_blank">
          {child}
        </StyledA>
      );
    }
    return <>{child}</>;
  };
  return (
    <LinkWrapper>
      <Wrapper {...rest}>
        <Label>{children}</Label>
      </Wrapper>
    </LinkWrapper>
  );
};

export default Button;
