import React from 'react';
import styled from 'styled-components';
import Footer from '../components/footer';
import Header from '../components/header';
import SEO from '../components/seo';
import Button from '../components/ui/button';
import Layout from '../components/ui/layout';

const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
`;

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="404" />
      <Header />
      <NotFoundWrapper>
        <h1>404</h1>
        <h1>Page not found</h1>
        <br/>
        <br/>
        <Button link='/' type='internal' buttonStyle='text'>
          <h1>Return to Home</h1>
        </Button>
      </NotFoundWrapper>
      <Footer />
    </Layout>
  );
};

export default NotFoundPage;
